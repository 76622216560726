import React from 'react';
import { ConfigProvider, Typography, PageHeader } from '@arco-design/web-react';
import AITextChecker from './components/AITextChecker';

const { Title, Paragraph } = Typography;

export const TITLE_COLOR = '#001A4D';

const App = () => {
  return (
    <ConfigProvider>
      <div style={{ 
        margin: 0, 
        padding: 0, 
        boxSizing: 'border-box',
        minHeight: '100vh',
        background: 'radial-gradient(circle at center, rgba(52, 145, 250, 0.1) 35%, rgba(255, 255, 255, 1) 70%)',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <PageHeader
          style={{
            background: 'rgba(255, 255, 255, 0.5)', 
            backdropFilter: 'blur(10px)',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          }}
          // title={
          //   <Space size="medium">
          //     <img src="/logo_ups.png" alt="Logo" style={{ height: '30px' }} />
          //     <Title heading={4} style={{ color: TITLE_COLOR, margin: 0, fontSize: 'clamp(18px, 4vw, 24px)' }}>
          //       UPS
          //     </Title>
          //   </Space>
          // }
        />
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px 20px 40px',
        }}>
          <div style={{
            textAlign: 'center',
            marginTop: '40px',
            marginBottom: '40px', // 调整这个值来控制与输入框的距离
          }}>
            <Title heading={2} style={{ 
              color: TITLE_COLOR, 
              marginBottom: '20px', 
              fontSize: 'clamp(40px, 8vw, 40px)' // 增加字号
            }}>
              Trusted{'\u00A0'}
              <span style={{
                background: 'rgba(52, 145, 250, 1)',
                padding: '5 20px',
                color: '#fff',
                borderRadius: '4px',
                fontWeight: 'bold',
              }}>
              {'\u00A0'}Bypass AI Detection{'\u00A0'}
              </span>
              {'\u00A0'}Tool
            </Title>
            <Paragraph style={{ 
              color: TITLE_COLOR, 
              fontSize: 'clamp(18px, 4vw, 18px)', 
              maxWidth: '750px', 
              margin: '0 auto',
              wordBreak: 'normal',
              overflowWrap: 'break-word'
            }}>
            Verified algorithms that transform your AI-generated text into human-like text by professional AI detectors, passing checks for coursework assignments and academic papers.
            </Paragraph>
          </div>
          <AITextChecker />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default App;
