import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Typography, Message, Radio, Steps, Skeleton } from '@arco-design/web-react';
import { IconUndo, IconRight, IconAlignLeft, IconPaste } from '@arco-design/web-react/icon';
import { TITLE_COLOR } from '../App';
import { useMediaQuery } from '@react-hook/media-query';

const { TextArea } = Input;
const { Paragraph } = Typography;

const AITextChecker = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [isOutputComplete, setIsOutputComplete] = useState(false);
  const [detector, setDetector] = useState('cnki');
  const streamingRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSteps, setShowSteps] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [stepsStatus, setStepsStatus] = useState(['process', 'process', 'process', 'process']);

  const textAreaStyle = {
    width: '100%',
    minHeight: '300px',
    backgroundColor: 'white',
    border: '1px solid #D9D9D9',
    borderRadius: '10px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    padding: '10px',
  };

  const sampleText = "本研究的目的在于探索创新激励机制，以激发知识型员工的潜能，提升企业的竞争力。通过深入了解知识型员工的特点和需求，结合现代管理理念和方法，构建一套科学、合理、有效的激励机制。具体而言，我们希望通过创新激励机制，为知识型员工提供更多的发展机会和空间满足他们的自我实现需求；同时，通过激励机制的优化，提高知识型员工的工作积极性和创新能力，为企业创造更多的值。例如，我们可以借鉴一些成功企业的经验。这些企业通过采用灵活的工作制度、丰富的培训资源以及多元化的激励方式，成功吸引和留住了大量的知识型人才，提升了企业的核心竞争力。";

  const handleSampleClick = () => {
    setInputText(sampleText);
  };

  const handlePasteText = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputText(text);
    } catch (err) {
      Message.error('无法访问剪贴板，请手动粘贴文本');
    }
  };

  useEffect(() => {
    if (streamingRef.current) {
      streamingRef.current.scrollTop = streamingRef.current.scrollHeight;
    }
  }, [outputText]);

  const handleSubmit = async () => {
    if (!inputText.trim()) {
      Message.error('请输入文本');
      return;
    }

    setLoading(true);
    setShowOutput(true);
    setOutputText('');
    setIsOutputComplete(false);
    setCurrentStep(0);
    setShowSteps(true);
    setShowSkeleton(true);
    setStepsStatus(['process', 'process', 'process', 'process']);

    const steps = [
      "理解输入文段",
      "检查AI痕迹",
      "调整表达去除痕迹",
      "最后检查和润色"
    ];

    const totalDuration = inputText.length * 60;
    const stepDuration = totalDuration / steps.length;

    for (let i = 0; i < steps.length; i++) {
      setCurrentStep(i);
      await new Promise(resolve => setTimeout(resolve, stepDuration));
      setStepsStatus(prev => prev.map((status, index) => 
        index <= i ? 'finish' : 'process'
      ));
    }

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    try {
      const response = await fetch(`${apiUrl}/api/rewrite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let fullText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        const parsedLines = lines
          .filter(line => line.startsWith('data: '))
          .map(line => line.slice(6));

        for (const line of parsedLines) {
          fullText += line;
          setOutputText(fullText);
        }
      }

      setIsOutputComplete(true);
    } catch (error) {
      console.error('Error:', error);
      Message.error('Request failed, please try again later');
    } finally {
      setLoading(false);
      setShowSteps(false);
      setShowSkeleton(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      Message.success('Copied to clipboard');
    });
  };

  const handleDetectorChange = (value) => {
    if (value === 'turnitin') {
      Message.info('Development in Progress, Stay Tuned');
    } else {
      setDetector(value);
    }
  };

  const handleUndo = () => {
    setInputText('');
    setOutputText('');
    setShowOutput(false);
    setIsOutputComplete(false);
  };

  // const emojiStyle = {
  //   transform: 'scale(1.5)',
  //   display: 'inline-block',
  //   marginBottom: '5px',
  // };

  const isMobile = useMediaQuery('(max-width: 767px)');

  // 添加自定义样式
  const customStepStyle = {
    fontSize: 'clamp(12px, 2.5vw, 14px)',
    fontWeight: 'normal', // 确保所有步骤文字都不加粗
  };

  return (
    <div style={{
      maxWidth: '800px',
      width: '100%',
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '20px',
      padding: '20px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{ position: 'relative', marginBottom: '20px' }}>
        <TextArea
          placeholder=""
          style={textAreaStyle}
          maxLength={1000}
          value={inputText}
          onChange={(value) => setInputText(value)}
        />
        {!inputText && (
          <>
            <div style={{
              position: 'absolute',
              left: '10px',
              top: '10px',
              fontSize: 'clamp(14px, 3vw, 16px)',
              color: '#86909C',
            }}>
              Enter text detected as AI-generated...
            </div>
            <div style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              gap: '10px',
            }}>
              <Button
                onClick={handleSampleClick}
                style={{
                  width: '100px',
                  height: '100px',
                  padding: '10px',
                  border: '1px solid #E5E6E8',
                  borderRadius: '10px',
                  color: '#86909C',
                  fontWeight: 'normal',
                  fontSize: 'clamp(12px, 2.5vw, 14px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#F7F8FA',
                  textAlign: 'center',
                }}
              >
                <IconAlignLeft style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                <span style={{ transform: 'translateX(-5px)' }}>Try Sample</span>
              </Button>
              <Button
                onClick={handlePasteText}
                style={{
                  width: '100px',
                  height: '100px',
                  padding: '10px',
                  border: '1px solid #E5E6E8',
                  borderRadius: '10px',
                  color: '#86909C',
                  fontWeight: 'normal',
                  fontSize: 'clamp(12px, 2.5vw, 14px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#F7F8FA',
                  textAlign: 'center',
                }}
              >
                <IconPaste style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                <span style={{ transform: 'translateX(-5px)' }}>Paste Text</span>
              </Button>
            </div>
          </>
        )}
        <div style={{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          fontSize: 'clamp(12px, 2.5vw, 14px)',
          color: '#86909C',
        }}>
          {isOutputComplete && (
            <IconUndo
              style={{ cursor: 'pointer' }}
              onClick={handleUndo}
            />
          )}
          <span>{inputText.length}/1000</span>
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: isMobile ? 'stretch' : 'center',
        gap: '10px',
        marginBottom: '20px',
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '10px', 
          flexWrap: 'wrap',
          width: isMobile ? '100%' : 'auto',
        }}>
          <span style={{ color: TITLE_COLOR, fontWeight: 'bold', fontSize: 'clamp(14px, 3vw, 16px)' }}>Detector:</span>
          <Radio.Group
            value={detector}
            onChange={handleDetectorChange}
            style={{ display: 'flex', gap: '0' }}
          >
            <Radio value="turnitin">Turnitin(English)</Radio>
            <Radio value="cnki">知网(中文)</Radio>
          </Radio.Group>
        </div>
        <Button 
          type="primary" 
          onClick={handleSubmit} 
          loading={loading}
          disabled={!inputText.trim() || loading}
          style={{
            height: '40px',
            fontSize: 'clamp(14px, 3vw, 16px)',
            fontWeight: 600,
            borderRadius: '10px',
            background: 'rgba(52, 145, 250, 1)',
            border: 'none',
            minWidth: '120px',
            width: isMobile ? '100%' : 'auto',
            marginTop: isMobile ? '10px' : '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          Start Rewriting <IconRight />
        </Button>
      </div>
      {showOutput && (
        <div 
          ref={streamingRef}
          style={{
            background: 'white',
            borderRadius: '10px',
            padding: '20px',
            minHeight: '200px',
            maxHeight: '400px',
            overflowY: 'auto',
            marginTop: '20px',
            border: '1px solid #D9D9D9',
            position: 'relative',
          }}
        >
          {showSteps && (
            <Steps 
              current={currentStep} 
              style={{ marginBottom: '20px' }}
              className="custom-steps"
            >
              {['Understanding Input', 'Checking AIGC', 'Starting Computing', 'Final Check'].map((step, index) => (
                <Steps.Step 
                  key={index} 
                  title={<span style={customStepStyle}>{step}</span>}
                  status={stepsStatus[index]}
                />
              ))}
            </Steps>
          )}
          {showSkeleton && (
            <div style={{
              opacity: isOutputComplete ? 0 : 1,
              transition: 'opacity 1s ease-out',
            }}>
              <Skeleton text={{ rows: 4 }} animation />
            </div>
          )}
          {isOutputComplete && (
            <div style={{
              opacity: isOutputComplete ? 1 : 0,
              transition: 'opacity 1s ease-in',
            }}>
              <Paragraph 
                copyable={{ text: outputText, onCopy: copyToClipboard }} 
                style={{ fontSize: 'clamp(14px, 3vw, 16px)', whiteSpace: 'pre-wrap' }}
              >
                {outputText || '改写结果将显示在这里…'}
              </Paragraph>
            </div>
          )}
          <div style={{
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            fontSize: 'clamp(12px, 2.5vw, 14px)',
            color: '#86909C',
          }}>
            {isOutputComplete ? outputText.length : 0}/1000
          </div>
        </div>
      )}
    </div>
  );
};

export default AITextChecker;
