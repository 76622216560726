import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@arco-design/web-react/dist/css/arco.css';
import { inject } from '@vercel/analytics';

inject();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
